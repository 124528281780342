<template>
  <v-container>
    <div class="">
      <h3 class="available-tests">Available Tests</h3>
      <div class="test-pool">
        <div class="group-items">
          <v-btn rounded color="primary" class="no-text-transform" small disabled>
            <stemble-latex content="$\text{pH}$" />
          </v-btn>
          <v-btn
            rounded
            color="primary"
            class="no-text-transform"
            small
            :disabled="ammoniaButtonDisabled"
            @click="
              ammoniaTestVisible = true;
              ammoniaButtonDisabled = true;
              testCounter += 1;
            "
          >
            <chemical-latex content="NH3 (15M)" />
          </v-btn>
        </div>
        <div class="test-pool-row">
          <div class="pool-group">
            <h4 class="group-title">Cations</h4>
            <div class="group-items">
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="silverButtonDisabled"
                @click="
                  silverTestVisible = true;
                  silverButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="Ag+" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="copperButtonDisabled"
                @click="
                  copperTestVisible = true;
                  copperButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="Cu^{2+}" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="bariumButtonDisabled"
                @click="
                  bariumTestVisible = true;
                  bariumButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="Ba^{2+}" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="hydrogenButtonDisabled"
                @click="
                  hydrogenTestVisible = true;
                  hydrogenButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="H+" />
              </v-btn>
            </div>
          </div>
          <div class="pool-group">
            <h4 class="group-title">Anions</h4>
            <div class="group-items">
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="carbonateButtonDisabled"
                @click="
                  carbonateTestVisible = true;
                  carbonateButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="CO3^{2-}" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="sulfateButtonDisabled"
                @click="
                  sulfateTestVisible = true;
                  sulfateButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="SO4^{2-}" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="chromateButtonDisabled"
                @click="
                  chromateTestVisible = true;
                  chromateButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="CrO4^{2-}" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="hydroxideButtonDisabled"
                @click="
                  hydroxideTestVisible = true;
                  hydroxideButtonDisabled = true;
                  testCounter += 1;
                "
              >
                <chemical-latex content="OH-" />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ppt-reactions-table-wrapper">
      <table class="ppt-reactions-table">
        <tbody>
          <tr>
            <th colspan="6" class="table-heading">Test Tube Reactions</th>
          </tr>
          <tr>
            <th></th>
            <th width="20%" class="test-tube-heading">Tube 1</th>
            <th width="20%" class="test-tube-heading">Tube 2</th>
            <th width="20%" class="test-tube-heading">Tube 3</th>
            <th width="20%" class="test-tube-heading">Tube 4</th>
            <th width="20%" class="test-tube-heading">Tube 5</th>
          </tr>
          <tr class="zebra-row">
            <td class="test-row-heading">
              <stemble-latex content="$\text{pH}$" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.ph + index" class="text-sm">
              <stemble-latex :content="tube.ph" />
            </td>
          </tr>
          <tr v-if="ammoniaTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="NH3 (15M)" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.ammonia + index" class="text-sm">
              <stemble-latex :content="tube.ammonia" />
            </td>
          </tr>
          <tr v-if="silverTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="Ag+" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.silver + index" class="text-sm">
              <stemble-latex :content="tube.silver" />
            </td>
          </tr>
          <tr v-if="copperTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="Cu^{2+}" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.copper + index" class="text-sm">
              <stemble-latex :content="tube.copper" />
            </td>
          </tr>
          <tr v-if="bariumTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="Ba^{2+}" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.barium + index" class="text-sm">
              <stemble-latex :content="tube.barium" />
            </td>
          </tr>
          <tr v-if="hydrogenTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="H+" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.hydrogen + index" class="text-sm">
              <stemble-latex :content="tube.hydrogen" />
            </td>
          </tr>
          <tr v-if="carbonateTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="CO3^{2-}" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.carbonate + index" class="text-sm">
              <stemble-latex :content="tube.carbonate" />
            </td>
          </tr>
          <tr v-if="sulfateTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="SO4^{2-}" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.sulfate + index" class="text-sm">
              <stemble-latex :content="tube.sulfate" />
            </td>
          </tr>
          <tr v-if="chromateTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="CrO4^{2-}" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.chromate + index" class="text-sm">
              <stemble-latex :content="tube.chromate" />
            </td>
          </tr>
          <tr v-if="hydroxideTestVisible" class="zebra-row">
            <td class="test-row-heading">
              <chemical-latex content="OH-" />
            </td>
            <td v-for="(tube, index) in tubes" :key="tube.hydroxide + index" class="text-sm">
              <stemble-latex :content="tube.hydroxide" />
            </td>
          </tr>
          <tr>
            <th colspan="6">Total Number of Tests: {{ testCounter }}</th>
          </tr>
          <tr class="response-table-heading">
            <th colspan="6" class="table-heading">Your Analysis</th>
          </tr>
          <tr>
            <th></th>
            <th class="test-tube-heading">Tube 1</th>
            <th class="test-tube-heading">Tube 2</th>
            <th class="test-tube-heading">Tube 3</th>
            <th class="test-tube-heading">Tube 4</th>
            <th class="test-tube-heading">Tube 5</th>
          </tr>
          <tr>
            <td class="test-row-heading">Type</td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type1"
                dense
                outlined
                :items="typeOptions"
                label="Type"
              ></v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type2"
                dense
                outlined
                :items="typeOptions"
                label="Type"
              ></v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type3"
                dense
                outlined
                :items="typeOptions"
                label="Type"
              ></v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type4"
                dense
                outlined
                :items="typeOptions"
                label="Type"
              ></v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type5"
                dense
                outlined
                :items="typeOptions"
                label="Type"
              ></v-select>
            </td>
          </tr>
          <tr>
            <td class="test-row-heading">Species</td>
            <td class="select-cell">
              <v-select
                v-model="inputs.species1"
                dense
                outlined
                :items="chooseSpeciesBasedOnType(inputs.type1)"
                label="Species"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.species2"
                dense
                outlined
                :items="chooseSpeciesBasedOnType(inputs.type2)"
                label="Species"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.species3"
                dense
                outlined
                :items="chooseSpeciesBasedOnType(inputs.type3)"
                label="Species"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.species4"
                dense
                outlined
                :items="chooseSpeciesBasedOnType(inputs.type4)"
                label="Species"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.species5"
                dense
                outlined
                :items="chooseSpeciesBasedOnType(inputs.type5)"
                label="Species"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div align="right">
      <v-btn rounded color="primary" class="no-text-transform mt-5" small @click="reset()">
        <stemble-latex content="$\text{Reset}$" />
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'CPPPptReactions',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      testCounter: 1,
      ammoniaTestVisible: false,
      ammoniaButtonDisabled: false,
      silverTestVisible: false,
      silverButtonDisabled: false,
      copperTestVisible: false,
      copperButtonDisabled: false,
      bariumTestVisible: false,
      bariumButtonDisabled: false,
      hydrogenTestVisible: false,
      hydrogenButtonDisabled: false,
      carbonateTestVisible: false,
      carbonateButtonDisabled: false,
      sulfateTestVisible: false,
      sulfateButtonDisabled: false,
      chromateTestVisible: false,
      chromateButtonDisabled: false,
      hydroxideTestVisible: false,
      hydroxideButtonDisabled: false,
      inputs: {
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        species1: null,
        species2: null,
        species3: null,
        species4: null,
        species5: null,
      },
      typeOptions: ['Acid', 'Base', 'Chloride', 'Nitrate', 'Sodium Salt'],
      // TODO: fix some of this latex (superscripts, subscripts, etc)
      acidSpeciesOptions: [
        {text: '$\\ce{H2SO4}$', value: 'H2SO4'},
        {text: '$\\ce{HNO3}$', value: 'HNO3'},
        {text: '$\\ce{HCl}$', value: 'HCl'},
      ],
      baseSpeciesOptions: [
        {text: '$\\ce{NH3}$', value: 'NH3'},
        {text: '$\\ce{NaOH}$', value: 'NaOH'},
        {text: '$\\ce{Na2S}$', value: 'Na2S'},
      ],
      chlorideSpeciesOptions: [
        {text: '$\\ce{BaCl2}$', value: 'BaCl2'},
        {text: '$\\ce{NaCl}$', value: 'NaCl'},
        {text: '$\\ce{CuCl2}$', value: 'CuCl2'},
      ],
      nitrateSpeciesOptions: [
        {text: '$\\ce{Cu(NO3)2}$', value: 'Cu(NO3)2'},
        {text: '$\\ce{Fe(NO3)3}$', value: 'Fe(NO3)3'},
        {text: '$\\ce{AgNO3}$', value: 'AgNO3'},
      ],
      sodiumSaltSpeciesOptions: [
        {text: '$\\ce{NaI}$', value: 'NaI'},
        {text: '$\\ce{Na2CO3}$', value: 'Na2CO3'},
        {text: '$\\ce{Na2SO4}$', value: 'Na2SO4'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    tubes() {
      let tube1 = {};
      let tube2 = {};
      let tube3 = {};
      let tube4 = {};
      let tube5 = {};

      // HCl
      const option1 = {
        ph: '~1',
        ammonia: '',
        silver: 'White ppt',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // HNO3
      const option2 = {
        ph: '~1',
        ammonia: '',
        silver: '',
        copper: '',
        barium: '',
        hydrogen: 'Blue litmus',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // H2SO4
      const option3 = {
        ph: '~1',
        ammonia: '',
        silver: '',
        copper: '',
        barium: 'White ppt',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // NaOH
      const option4 = {
        ph: '~12-14',
        ammonia: '',
        silver: 'Grey-brown ppt',
        copper: 'Light blue ppt',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // NH3
      const option5 = {
        ph: '~12-14',
        ammonia: '',
        silver: '',
        copper: 'Dark blue soln',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // Na2S
      const option6 = {
        ph: '~12-14',
        ammonia: '',
        silver: '',
        copper: 'Black ppt',
        barium: '',
        hydrogen: 'Rotten egg smell',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // NaCl
      const option7 = {
        ph: '~7',
        ammonia: '',
        silver: 'White ppt',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // BaCl2
      const option8 = {
        ph: '~7',
        ammonia: '',
        silver: 'White ppt',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: 'White ppt',
        sulfate: 'White ppt',
        chromate: 'Light yellow',
        hydroxide: '',
      };

      // CuCl2
      const option9 = {
        ph: '~7',
        ammonia: 'Dark blue soln',
        silver: 'White ppt',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: 'Orange brown',
        hydroxide: 'Blue ppt',
      };

      // AgNO3
      const option10 = {
        ph: '~7',
        ammonia: '',
        silver: '',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: 'White ppt',
        sulfate: '',
        chromate: 'Dark orange red',
        hydroxide: 'Grey-brown ppt',
      };

      // Cu(NO3)2
      const option11 = {
        ph: '~7',
        ammonia: '',
        silver: '',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: 'Orange brown',
        hydroxide: 'Blue ppt',
      };

      // Fe(NO3)3
      const option12 = {
        ph: '~3-5',
        ammonia: 'Reddish ppt',
        silver: '',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: 'Pale orange brown gelatinous',
        sulfate: '',
        chromate: '',
        hydroxide: 'Reddish ppt',
      };

      // NaI
      const option13 = {
        ph: '~7',
        ammonia: '',
        silver: 'Yellow ppt',
        copper: '',
        barium: '',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // Na2SO4
      const option14 = {
        ph: '~7',
        ammonia: '',
        silver: '',
        copper: '',
        barium: 'White ppt',
        hydrogen: '',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      // Na2CO3
      const option15 = {
        ph: '~12-14',
        ammonia: '',
        silver: 'White ppt',
        copper: 'Light blue ppt',
        barium: 'White ppt',
        hydrogen: 'Bubbles',
        carbonate: '',
        sulfate: '',
        chromate: '',
        hydroxide: '',
      };

      if (this.versionNumber.value === 1) {
        tube1 = option7;
        tube2 = option1;
        tube3 = option13;
        tube4 = option4;
        tube5 = option10;
      } else if (this.versionNumber.value === 2) {
        tube1 = option8;
        tube2 = option2;
        tube3 = option14;
        tube4 = option5;
        tube5 = option11;
      } else if (this.versionNumber.value === 3) {
        tube1 = option9;
        tube2 = option3;
        tube3 = option15;
        tube4 = option6;
        tube5 = option12;
      } else if (this.versionNumber.value === 4) {
        tube1 = option13;
        tube2 = option4;
        tube3 = option2;
        tube4 = option10;
        tube5 = option7;
      } else if (this.versionNumber.value === 5) {
        tube1 = option11;
        tube2 = option3;
        tube3 = option14;
        tube4 = option5;
        tube5 = option8;
      }

      return [tube1, tube2, tube3, tube4, tube5];
    },
  },
  methods: {
    chooseSpeciesBasedOnType(type) {
      let speciesOptions = [];

      switch (type) {
        case 'Acid':
          speciesOptions = this.acidSpeciesOptions;
          break;
        case 'Base':
          speciesOptions = this.baseSpeciesOptions;
          break;
        case 'Chloride':
          speciesOptions = this.chlorideSpeciesOptions;
          break;
        case 'Nitrate':
          speciesOptions = this.nitrateSpeciesOptions;
          break;
        case 'Sodium Salt':
          speciesOptions = this.sodiumSaltSpeciesOptions;
          break;
      }

      return speciesOptions;
    },
    reset() {
      this.ammoniaButtonDisabled = false;
      this.ammoniaTestVisible = false;
      this.silverTestVisible = false;
      this.silverButtonDisabled = false;
      this.copperTestVisible = false;
      this.copperButtonDisabled = false;
      this.bariumTestVisible = false;
      this.bariumButtonDisabled = false;
      this.hydrogenTestVisible = false;
      this.hydrogenButtonDisabled = false;
      this.carbonateTestVisible = false;
      this.carbonateButtonDisabled = false;
      this.sulfateTestVisible = false;
      this.sulfateButtonDisabled = false;
      this.chromateTestVisible = false;
      this.chromateButtonDisabled = false;
      this.hydroxideTestVisible = false;
      this.hydroxideButtonDisabled = false;

      this.inputs.type1 = null;
      this.inputs.type2 = null;
      this.inputs.type3 = null;
      this.inputs.type4 = null;
      this.inputs.type5 = null;

      this.inputs.species1 = null;
      this.inputs.species2 = null;
      this.inputs.species3 = null;
      this.inputs.species4 = null;
      this.inputs.species5 = null;

      this.testCounter = 1;
    },
  },
};
</script>
<style scoped>
.available-tests {
  margin-bottom: 0.75rem;
  line-height: 1.125;
  font-size: 1.25rem;
  font-weight: bold;
}

.test-pool {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.75rem;
}

.test-pool-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1.25rem;
}

.pool-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  padding: 0 0.625rem 0.75rem 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.group-title {
  font-size: 1rem;
  line-height: 1;
  background: white;
  padding: 0 0.25rem;
  margin: -0.5rem 0 0 -0.25rem;
}

.group-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top:;
}

.group-items > * {
  margin: 0 !important;
}

.ppt-reactions-table-wrapper {
  overflow-x: auto;
  margin: 0 -0.5rem;
}

.ppt-reactions-table {
  text-align: left;
  border-collapse: collapse;
  font-size: 14px;
}

.ppt-reactions-table th,
.ppt-reactions-table td {
  padding: 0.375rem;
}

.ppt-reactions-table th:first-child,
.ppt-reactions-table td:first-child {
  padding-left: 0.5rem;
}

.ppt-reactions-table th:last-child,
.ppt-reactions-table td:last-child {
  padding-right: 0.5rem;
}

.ppt-reactions-table .zebra-row td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.ppt-reactions-table .zebra-row:nth-of-type(2n) td {
  background-color: #f9f9f9;
}

.ppt-reactions-table .response-table-heading th {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 2rem;
}

.ppt-reactions-table >>> .v-text-field__details {
  display: none !important;
}

.ppt-reactions-table >>> .v-input__slot {
  margin: 0 !important;
}

.ppt-reactions-table
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.ppt-reactions-table >>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 8px !important;
}

.ppt-reactions-table >>> .v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}

.ppt-reactions-table >>> .v-input__icon {
  width: 18px;
  min-width: 18px;
}

.ppt-reactions-table >>> .v-text-field--outlined fieldset {
  padding-left: 4px;
}

.ppt-reactions-table .table-heading {
  text-align: left;
  padding-top: 1rem;
  line-height: 1.125;
  font-size: 1.25rem;
  font-weight: bold;
}

.ppt-reactions-table .test-tube-heading {
  font-size: 1rem;
  font-weight: bold;
}

.ppt-reactions-table .test-row-heading {
  font-weight: bold;
}

.ppt-reactions-table .text-sm {
  font-size: 12px;
}

.ppt-reactions-table .select-cell {
  font-size: 14px;
  font-weight: bold;
}
</style>
